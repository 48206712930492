import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";
import { IconButton, Tab, Tabs, Typography, Zoom } from "@mui/material";
import { styled } from "@mui/styles";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#ffffff",
  },
  drawer: {
    height: "100%",
    width: "64px",
    borderRight: "1px solid #e0e0e0",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0 8px 8px 0",
  },
  drawerContainer: {
    padding: "8px",
    overflow: "hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

const MyTab = styled(Tab)(({ theme }) => ({
  transition: "all .4s ease-out",
  color: `${theme.palette.info.contrastText}`,
  padding: "2px",
  minWidth: "auto",
  "&.Mui-selected": {
    backgroundColor: `${theme.palette.info.main}`,
    color: `${theme.palette.info.contrastText}`,
    borderRadius: "4px",
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    TransitionComponent={Zoom}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
    // border: "1px solid #e0e0e0",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    fontSize: "1rem",
  },
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export const SideBar = (props) => {
  const classes = useStyles(props);

  const history = useHistory();

  const SelectedTab = () => {
    let id = "";
    if (props?.sidebarlist) {
      props?.sidebarlist.map((l) => {
        if (window.location.pathname === l.path) {
          id = l.id;
        }
        return l;
      });
      return id;
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.drawer}>
        <div className={classes.drawerContainer}>
          <Tabs
            orientation={"vertical"}
            variant="scrollable"
            value={SelectedTab()}
            selectionFollowsFocus
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            {props?.sidebarlist &&
              props?.sidebarlist.map((navBar, index) => (
                <BootstrapTooltip
                  key={navBar?.id}
                  title={navBar?.name}
                  placement="right"
                >
                  <MyTab
                    id={navBar?.id}
                    key={navBar?.id}
                    value={navBar?.id}
                    icon={navBar?.icon}
                    // label={navBar?.name}
                    aria-label={navBar?.name}
                    {...a11yProps(navBar?.id)}
                    onClick={() => history.push({ pathname: navBar?.path })}
                  />
                </BootstrapTooltip>
              ))}
          </Tabs>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <IconButton>
              <SettingsOutlinedIcon style={{ color: "#ffffff" }} />
            </IconButton>
            <Typography variant="caption" color="white">
              {`v ${localStorage.getItem("version")}`}
            </Typography>
          </div>
        </div>
      </Paper>
    </div>
  );
};
