import { makeStyles } from "@mui/styles";
import { TopNavBar } from "../../components";
import React from "react";
import { QueueScenarios } from "./queueScenario";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { AlertProps } from "../../utils";
import { AlertContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
}));

export const QueueItems = (props) => {
  const { queue } = props;
  const classes = useStyles();
  const alertMessage = React.useContext(AlertContext);

  const [state, setState] = React.useState("");
  const [scenarios, setScenarios] = React.useState([]);

  const [currantScenario, setcurrantScenario] = React.useState("");

  /* Queue Data for read query from default and dynamic search params */
  const [queryData, setQueryData] = React.useState({});

  const location = useLocation();

  React.useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    const data = location.state;
    const getQueueName = url.get("currantQueue");
    if (getQueueName && data) {
      setState(getQueueName);
      setQueryData(data);
      getScenariosData(data?.queue_id);
    } else {
      if (queue) {
        setState(queue?.queuename);
        getScenariosData(queue?._id);

        setQueryData({ queueid: queue?.queueid, queue_id: queue?._id });
      }
    }

    // eslint-disable-next-line
  }, [queue]);
  const db = atob(sessionStorage.getItem("ProjectDbname"));
  const getScenariosData = async (queueid) => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "queuescenarios",
        filter: `queuescenarios.queueid=='${queueid}'&&queuescenarios.active==true && queuescenarios.activestatus==true`,
        sort: "queuescenarios.scenarioname ASC",
        return_fields: "queuescenarios",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result;
            setScenarios(data);
            setcurrantScenario(data[0]?._id);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    } catch (error) {}
  };

  const updateScenaorio = (value) => {
    setcurrantScenario(value);
  };

  return (
    <React.Fragment>
      <TopNavBar
        QueueName={state}
        Scenarios={scenarios}
        defaultScenarios={currantScenario}
        updateScenaorio={updateScenaorio}
      />
      <div style={{ height: "calc(100% - 64px)" }}>
        <div className={classes.root}>
          <QueueScenarios
            queueId={queryData?.queue_id}
            currantScenario={currantScenario}
            updateScenaorio={updateScenaorio}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
