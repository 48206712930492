import axios from "axios";
import React from "react";
import { MainPanel, SidePanel } from "../../../components";
import { AlertContext } from "../../../contexts";
import { AlertProps } from "../../../utils";

export const ListPanel = (props) => {
  const {
    queueId,
    scenarioId,
    each,
    stateid,
    scenarioState,
    getScenarioStateCount = () => false,
    getScenarioStateData,
    updateScenaorio,
    setValue,
    updateFavState,
    // favlist,
    favtab,
  } = props;
  const alertMessage = React.useContext(AlertContext);

  const [logState, setState] = React.useState([]);
  const [selectedTask, setSelectedTask] = React.useState({});
  const [isLoading, Setloading] = React.useState(false);
  const [config, setconfig] = React.useState([]);
  const [timeline, setTimeline] = React.useState([]);
  const db = atob(sessionStorage.getItem("ProjectDbname"));
  const [alignment, setAlignment] = React.useState("");

  // const [selected,setSelected]=React.useState({})

  const updateTimeLine = (value) => {
    setTimeline(value);
  };

  React.useEffect(() => {
    // getAlltransactionlog(queueId, scenarioId);
    // debugger;
    if (queueId && scenarioId) {
      getTaskViewerConifg(queueId, scenarioId, each, stateid, favtab);
    }

    setSelectedTask({});
    //eslint-disable-next-line
  }, [queueId, scenarioId, favtab]);

  const getFavouriteList = async (
    queueId,
    scenarioId,
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8,
    field9,
    favtab
  ) => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "QDMATPtransactionlog",
        filter: `QDMATPtransactionlog.queueIds=='${queueId}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioId}' and QDMATPtransactionlog.favourite==true `,
        sort: `QDMATPtransactionlog._key ASC`,
        return_fields: `{field1:QDMATPtransactionlog.payload.inputDoc.${field1},field2:QDMATPtransactionlog.payload.inputDoc.${field2},field9:QDMATPtransactionlog.payload.inputDoc.${field9},field4:QDMATPtransactionlog.payload.inputDoc.${field3},field5:QDMATPtransactionlog.payload.inputDoc.${field4},field6:QDMATPtransactionlog.payload.ticketId,field7:first( for queuestates in queuestates filter queuestates._id==QDMATPtransactionlog.payload.statesid[0] return {states:queuestates.states,statesid:queuestates._id}),favourite:QDMATPtransactionlog.favourite,key:QDMATPtransactionlog._key,id:QDMATPtransactionlog._id}`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        if (res.data.Code === 201) {
          // debugger;
          let data = res.data.result;
          if (favtab) {
            setState(data);
            updateFavState(data);
          } else {
            updateFavState(data);
          }

          Setloading(false);
        } else {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      });
    } catch (error) {}
  };

  const getTaskViewerConifg = async (
    queueId,
    scenarioId,
    each,
    stateid,
    favtab
  ) => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "Taskviewer_config",
        filter: `Taskviewer_config.queueid=='${queueId}' `,
        return_fields: `Taskviewer_config`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      Setloading(true);

      await axios(config)
        .then((res) => {
          // debugger;
          if (res.data.Code === 201) {
            let data = res.data.result[0];
            let field1 = data?.field1;
            let field2 = data?.field2;
            let field3 = data?.field3;
            let field4 = data?.field4;
            let field5 = data?.field5;
            let field6 = data?.field6;
            let field7 = data?.field7;
            let field8 = data?.field8;
            let field9 = data?.field9;

            setconfig({
              field1: data?.field1,
              field2: data?.field2,
              field3: data?.field3,
              field4: data?.field4,
              field5: data?.field5,
              field6: data?.field6,
              field7: data?.field6,
              field8: data?.field6,
              field9: data?.field6,
            });
            // console.log(
            //   tasktitle,
            //   tasksubtitle,
            //   taskdate,
            //   taskdescription,
            //   tasktype
            // );
            if (favtab) {
              getFavouriteList(
                queueId,
                scenarioId,
                field1,
                field2,
                field3,
                field4,
                field5,
                field6,
                field7,
                field8,
                field9,
                favtab
              );
            } else {
              getAlltransactionlog(
                queueId,
                scenarioId,
                field1,
                field2,
                field3,
                field4,
                field5,
                field6,
                field7,
                field8,
                field9,
                each,
                stateid
              );
            }
          } else if (res.data.error) {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: `taskviewer_config-${res.data.error}`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const getAlltransactionlog = async (
    queueId,
    scenarioId,
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8,
    field9,
    each,
    stateid
  ) => {
    const db = atob(sessionStorage.getItem("ProjectDbname"));
    // console.log(tasktitle, taskid);
    try {
      let params = each
        ? {
            db_name: `${db}`,
            entity: "QDMATPtransactionlog",
            filter: `QDMATPtransactionlog.queueIds=='${queueId}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioId}'and QDMATPtransactionlog.payload.statesid[0]=='${stateid}'`,
            sort: `QDMATPtransactionlog._key ASC`,
            return_fields: `{field1:QDMATPtransactionlog.payload.inputDoc.${field1},
            field2:QDMATPtransactionlog.payload.inputDoc.${field2},field9:QDMATPtransactionlog.payload.inputDoc.${field9},field4:QDMATPtransactionlog.payload.inputDoc.${field3},field5:QDMATPtransactionlog.payload.inputDoc.${field4},field6:QDMATPtransactionlog.payload.ticketId,field7:first( for queuestates in queuestates filter queuestates._id==QDMATPtransactionlog.payload.statesid[0] return {states:queuestates.states,statesid:queuestates._id}),favourite:QDMATPtransactionlog.favourite,key:QDMATPtransactionlog._key,id:QDMATPtransactionlog._id}`,
          }
        : {
            db_name: `${db}`,
            entity: "QDMATPtransactionlog",
            filter: `QDMATPtransactionlog.queueIds=='${queueId}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioId}'`,
            sort: `QDMATPtransactionlog._key ASC`,
            return_fields: `{field1:QDMATPtransactionlog.payload.inputDoc.${field1},field2:QDMATPtransactionlog.payload.inputDoc.${field2},field9:QDMATPtransactionlog.payload.inputDoc.${field9},field4:QDMATPtransactionlog.payload.inputDoc.${field3},field5:QDMATPtransactionlog.payload.inputDoc.${field4},field6:QDMATPtransactionlog.payload.ticketId,field7:first( for queuestates in queuestates filter queuestates._id==QDMATPtransactionlog.payload.statesid[0] return {states:queuestates.states,statesid:queuestates._id}),favourite:QDMATPtransactionlog.favourite,key:QDMATPtransactionlog._key,id:QDMATPtransactionlog._id}`,
          };

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            // debugger;
            let data = res.data.result;
            // console.log(data);

            /* for (let j = 0; j < data.length; j++) {
              for (let i = 0; i < favlist.length; i++) {
                if (data[j].key === favlist[i].key) {
                  Object.assign(data[j], { favourite: true });
                } else {
                  Object.assign(data[j]);
                }
              }
            } */
            // console.log(l);
            setState(data);

            getFavouriteList(
              queueId,
              scenarioId,
              field1,
              field2,
              field3,
              field4,
              field5,
              field6,
              field7,
              field8,
              field9,
              false
            );
            setTimeout(() => {
              Setloading(false);
            }, 1000);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const getActionPerformedState = async (ticketId) => {
    const db = atob(sessionStorage.getItem("ProjectDbname"));
    try {
      let params = {
        db_name: `${db}`,
        entity: "QDMATPtransactionlog",
        filter: `QDMATPtransactionlog.ticketId == '${ticketId}'`,
        return_fields: `QDMATPtransactionlog.payload.statesid`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      Setloading(true);

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            // debugger;
            const data = res.data.result[0];
            const TabValue =
              scenarioState.findIndex((l) => data[0] === l._id) + 2;
            setValue(`${TabValue}`);
            // scenarioState?.filter(() => data[0] === scenarioState?._id);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };
  const onCardClicked = (cardData) => {
    let arr = cardData;
    // console.log(arr);
    setSelectedTask(arr);
    setAlignment("");
    // setSelected(cardData)
  };

  const onFavIconClicked = (data) => {
    // let val = { ...data, favourite: !data.favourite };
    // debugger;
    //local fav store
    /* if (data.favourite === true) {
      if (!favtab) {
        let existingFavList = favlist.filter((d, i) => d.key !== data.key);
        let updatefav = logState.map((l, i) =>
          l.key === data.key ? { ...l, favourite: !data.favourite } : l
        );
        setState(updatefav);
        updateFavState(existingFavList);
      } else {
        let existingFavList = favlist.filter((d, i) => d.key !== data.key);
        setState(existingFavList);
        updateFavState(existingFavList);
      }
    } else {
      let updatefav = logState.map((l, i) =>
        l.key === data.key ? { ...l, favourite: !data.favourite } : l
      );
      setState(updatefav);
      updateFavState([...favlist, { ...data, favourite: !data.favourite }]);
    } */
    addThisFavourite(data.key, data.favourite);
  };

  const addThisFavourite = async (key, favourite) => {
    const db = atob(sessionStorage.getItem("ProjectDbname"));
    try {
      let params = [
        {
          db_name: `${db}`,
          entity: "QDMATPtransactionlog",
          filter: {
            _key: `${key}`,
          },
          is_metadata: true,
          metadataId: `${process.env.REACT_APP_metaID}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: {
            favourite: !favourite,
          },
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        if (res.data.Code === 201) {
          let data = res.data.Result[0];
          let isFav = data.properties.doc.favourite;
          if (!favtab) {
            getTaskViewerConifg(queueId, scenarioId, each, stateid, false);
            getFavouriteList(
              queueId,
              scenarioId,
              config.field1,
              config.field2,
              config.field3,
              config.field4,
              config.field5,
              config.field6,
              config.field7,
              config.field8,
              config.field9,
              false
            );
            getScenarioStateCount(queueId, scenarioId);
          } else {
            getTaskViewerConifg(queueId, scenarioId, each, stateid, true);
            getScenarioStateCount(queueId, scenarioId);
          }

          alertMessage.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.success,
            msg: isFav
              ? "Added to favourite list!"
              : "Removed from favourite list",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else {
          alertMessage.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }

        // console.log(res);
      });
    } catch (error) {}
  };

  const onUserActionPerformed = (ticketid) => {
    setSelectedTask({});
    setState([]);
    Setloading(true);
    setTimeout(async () => {
      await getActionPerformedState(ticketid);
      await getScenarioStateData(queueId, scenarioId);
      await getTaskViewerConifg(queueId, scenarioId, each, stateid, false);
      getScenarioStateCount(queueId, scenarioId);
      updateScenaorio(scenarioId);
      Setloading(false);
    }, 2000);
  };

  const updateState = (value) => {
    setAlignment(value);
  };

  return (
    <React.Fragment>
      <div
        style={{
          height: "100%",
          display: "flex",
          background: "#ffffff",
        }}
      >
        <SidePanel
          isLoading={isLoading}
          config={config}
          logState={logState}
          getTaskViewerConifg={getTaskViewerConifg}
          each={each}
          queueId={queueId}
          scenarioId={scenarioId}
          selectedTask={selectedTask}
          stateid={stateid}
          onCardClicked={onCardClicked}
          onFavIconClicked={onFavIconClicked}
        />
        <MainPanel
          isLoading={isLoading}
          alignment={alignment}
          updateState={updateState}
          logState={logState}
          selectedTask={selectedTask}
          scenarioState={scenarioState}
          onUserActionPerformed={onUserActionPerformed}
          updateTimeLine={updateTimeLine}
          timeline={timeline}
        />
      </div>
    </React.Fragment>
  );
};
