// import axios from "axios";
import React from "react";

import { withAllContexts, withQueueModal, withSideBars } from "./../../HOCs";
import { QueueItems } from "./queueItems";
class QueueItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.getProjectDetails();
  }

  render() {
    const { queues } = this.props;
    return (
      <>
        <QueueItems queue={queues[0]} />
      </>
    );
  }
}

export default withSideBars(withQueueModal(withAllContexts(QueueItem)));
