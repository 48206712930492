import React from "react";
import {
  Divider,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { styled as muiStyle } from "@mui/material/styles";

import StarIcon from "@mui/icons-material/Star";
import { TagsIcon } from "../../assets";
import { useTheme } from "@emotion/react";
import moment from "moment";

export const CardStyled = styled.div`
  padding: ${(props) => (props.m === "large" ? "12px 12px" : "8px 8px")};
  background: ${(props) => (props.selected ? props.hovercolor : "#ffffff")};
  border-top: 1px solid;
  border-bottom: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100px;
  cursor: pointer;
  border-color: #e0e0e0;
  border-radius: ${(props) => (props.type === "main" ? "8px" : "0px")};
  transition: border-color 0.3s ease-in-out;
  &:hover {
    /* background: ${(props) => props.hovercolor}; */
    /* opacity: 0.7; */
    border: 2px solid;
    border-color: ${(props) => props.color};
  }
`;

export const CardStyle = styled(CardStyled)`
  cursor: inherit;
  border: 1px solid #e0e0e0;
  &:hover {
    /* background: ${(props) => props.hovercolor}; */
    /* opacity: 0.7; */
    border: 1px solid #e0e0e0;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin: 2px;
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledToggleButtonGroup = muiStyle(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    border: 0,
    fontSize: 12,
    // "&.Mui-disabled": {
    //   border: 0,
    // },

    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
  "& .Mui-selected": {
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderColor: `${theme.palette.primary.main} !important`,
    color: "#fff !important",

    "& .MuiToggleButton-label": {
      color: "#fff !important",
    },
  },
}));

export const Cards = (props) => {
  const {
    type,
    index,
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8,
    field9,
    data,
    favourite,
    performUserAction = () => false,
    onCardClicked = () => false,
    onFavIconClicked = () => false,
    action,
    selectedTask,
    alignment,
    updateState,
  } = props;
  // const [alignment, setAlignment] = React.useState("");
  const theme = useTheme();
  // const [select, setSelected] = React.useState({});

  /* React.useEffect(() => {
    setSelected({});
  }, [select]); */

  const a = new Date(field9 * 1000);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const moments = moment(a).startOf("hour").fromNow();

  // var sec = a.getSeconds();

  const handleAlignment = (event, newAlignment) => {
    updateState(newAlignment);
    performUserAction(newAlignment, field6);
  };
  const handleSelect = (data) => {
    onCardClicked(data);
  };

  const onClickFav = (data) => {
    onFavIconClicked(data);
  };

  return (
    <Grid item lg={12} md={12} xs={12} sm={12} key={index}>
      {type === 1 && (
        <CardStyled
          onClick={() => handleSelect(data, field3)}
          hovercolor={theme.palette.info.light}
          color={theme.palette.info.main}
          // key={index}
          selected={selectedTask?.key === data?.key ? true : false}
        >
          <FlexSpaceBetween>
            <FlexRow>
              <Typography variant="body1"> {field1 ?? ""}</Typography>

              {field9 && (
                <>
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ mx: 0.5, my: 0.5 }}
                  />
                  <Typography
                    variant="caption"
                    style={{
                      fontSize: "9px",
                    }}
                    color="InactiveCaptionText"
                  >
                    {`${moments}`}
                    {/* ${a.toGMTString()} */}
                  </Typography>
                </>
              )}
            </FlexRow>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "600", fontSize: "10px" }}
            >
              {field6 ?? ""}
            </Typography>
          </FlexSpaceBetween>

          <FlexRow>
            <Typography
              variant="caption"
              style={{ fontWeight: "600", fontSize: "10px" }}
              color="InactiveCaptionText"
            >
              {field4 ?? ""}
            </Typography>
            <Divider
              flexItem
              orientation="vertical"
              sx={{ mx: 0.5, my: 0.5 }}
            />
            <Typography
              variant="caption"
              style={{ fontWeight: "600", fontSize: "10px" }}
              color="InactiveCaptionText"
            >
              {field2 ?? ""}
            </Typography>
            {/* <Divider
              flexItem
              orientation="vertical"
              sx={{ mx: 0.5, my: 0.5 }}
            /> */}
          </FlexRow>
          <FlexRow>
            <Typography
              variant="caption"
              style={{
                fontWeight: "600",
                fontSize: "10px",
                textTransform: "uppercase",
              }}
              color="InactiveCaptionText"
            >
              {field5 ?? ""}
            </Typography>
            {field8 && (
              <>
                <Divider
                  flexItem
                  orientation="vertical"
                  sx={{ mx: 0.5, my: 0.5 }}
                />
                <Typography>field8</Typography>
              </>
            )}
          </FlexRow>
          <FlexSpaceBetween>
            <FlexRow>
              <TagsIcon size="0.8rem" color={theme.palette.text.disabled} />
              <Typography
                variant="caption"
                color="InactiveCaptionText"
                style={{
                  marginLeft: "8px",
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                {field7 ?? ""}
              </Typography>
            </FlexRow>
            <IconButton onClick={() => onClickFav(data)}>
              <StarIcon
                color={favourite ? "warning" : "disabled"}
                style={{ fontSize: "1rem" }}
              />
            </IconButton>
          </FlexSpaceBetween>
        </CardStyled>
      )}
      {type === 2 && (
        <CardStyle type={"main"} m={"large"}>
          <FlexSpaceBetween>
            <FlexRow>
              <Typography> {field1 ?? ""}</Typography>

              {field9 && (
                <>
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ mx: 0.5, my: 0.5 }}
                  />
                  <Typography
                    variant="caption"
                    style={{
                      fontSize: "9px",
                    }}
                    color="InactiveCaptionText"
                  >
                    {` ${a.toLocaleDateString(undefined, options)}`}
                    {/* ${a.toGMTString()} */}
                  </Typography>
                </>
              )}
            </FlexRow>

            <Typography
              variant="subtitle2"
              style={{ fontWeight: "600", fontSize: "12px" }}
            >
              {field6 ?? ""}
            </Typography>
          </FlexSpaceBetween>
          <FlexRow>
            <Typography
              variant="caption"
              color="InactiveCaptionText"
              style={{ fontWeight: "600" }}
            >
              {field4 ?? ""}
            </Typography>
            {/* <hr style={{ height: 10, margin: "0px 8px" }} /> */}
            <Divider
              flexItem
              orientation="vertical"
              sx={{ mx: 0.5, my: 0.5 }}
            />
            <Typography
              variant="caption"
              style={{ fontWeight: "600" }}
              color="InactiveCaptionText"
            >
              {`ID - ${field2 ?? ""}`}
            </Typography>
            <Divider
              flexItem
              orientation="vertical"
              sx={{ mx: 0.5, my: 0.5 }}
            />
            <Typography
              variant="caption"
              style={{ fontWeight: "600", textTransform: "uppercase" }}
              color="InactiveCaptionText"
            >
              {field5 ?? ""}
            </Typography>
          </FlexRow>
          <FlexSpaceBetween>
            <FlexRow>
              <TagsIcon size="1rem" color={theme.palette.info.main} />
              <Typography
                variant="subtitle2"
                color="InactiveCaptionText"
                style={{ marginLeft: "8px", fontWeight: "600" }}
              >
                {field7 ?? ""}
              </Typography>
            </FlexRow>
            {action && (
              <FlexRow>
                <StyledToggleButtonGroup
                  size="small"
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                >
                  {action?.map((action, index) => (
                    <ToggleButton
                      size="small"
                      value={action.action}
                      key={index}
                      // className={classes.ToggleButton}
                      style={{
                        border: "1px solid #01205C",
                        borderRadius: "8px",
                        padding: "4px 6px",
                        color: "#01205C",
                      }}
                    >
                      {action.action}
                    </ToggleButton>
                  ))}
                </StyledToggleButtonGroup>
              </FlexRow>
            )}
          </FlexSpaceBetween>
        </CardStyle>
      )}
    </Grid>
  );
};

/* {props?.action?.map((action, index) => (
                  <Button size="small" sx={{ m: "4px" }} variant="outlined">
                    {action}
                  </Button>
                ))} */
