/**
 * @author Gayathri Murugesan
 * @email gayathri.murugesan@ainqa.com
 * @create date 2022-04-01
 * @modify date 2022-04-01
 * @desc The first component of the application, all the required context and routes are used here
 * to setup the application.
 */

 import React from "react";
 import AppAuth from "./App.auth";
 import AppTheme from "./App.theme";
 import AppAlert from "./App.alert";
 import AppErrorBoundary from "./App.errorBoundry";
 import RouterApp from "./router";
 // import ApolloClient from "./App.gqlclient";
 import AppDrawer from "./App.drawer";
 import { CssBaseline } from "@mui/material";
 import AppDialog from "./App.dialog";
 import AppBackdrop from "./App.backdrop";
 // import AppFirebase from "./App.firebase"; //For Push Notification thing
 
 const App = () => {
   return (
    <AppErrorBoundary>
    <AppAuth>
      <AppTheme>
        <CssBaseline />
        <AppAlert>
          <AppDialog>
            <AppDrawer>
              <AppBackdrop>
                {/* <AppFirebase> */}
                <RouterApp />
                {/* </AppFirebase> */}
              </AppBackdrop>
            </AppDrawer>
          </AppDialog>
        </AppAlert>
      </AppTheme>
    </AppAuth>
  </AppErrorBoundary>
    
   );
 };
 export default App;
 