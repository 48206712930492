import { Typography } from "@mui/material";
import React from "react";

export const PageEmpty = () => {
  return (
    <div
      style={{
        padding: "8px",
        borderRadius: "50%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          padding: "8px",
          height: "50px",
          width: "50px",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#E9EFF8",
        }}
      >
        <img
          style={{
            height: "30px",
            width: "30px",
            objectFit: "contain",
          }}
          src={`/images/icons/box.png`}
          alt={"No Data Imge"}
        ></img>
      </div>
      <Typography variant="caption" color="GrayText" noWrap>
        {"No Data Available!"}
      </Typography>
    </div>
  );
};
