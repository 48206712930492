import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import styled from "styled-components";
import { TaskIcon } from "../../assets";
import { useTheme } from "@emotion/react";

export const QueueCard = styled.div`
  padding: 12px 12px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* min-height: 100px; */
  cursor: pointer;
  margin: 8px 0px;
  border-radius: 12px;
  &:hover {
    background: ${(props) => props.hovercolor};
    border-color: ${(props) => props.color};
  }

  &:hover .title {
    color: #2a60bc;
  }
`;

const useStyles = makeStyles((theme) => ({
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "52px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderBottom: "1px solid #bdbdbd",
    borderRadius: "8px 8px 0 0",
  },
  closeIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: "#fff",
    position: "fixed",
    // left: "50%",
    // transform: "translate(-50%)",
    // bottom: -60,
    cursor: "pointer",
    backgroundColor: theme.palette.info.main,
    top: -20,
    right: -20,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
  },
}));

export const ModalQueueList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const NavigateRoute = (queue) => {
    history.push({
      pathname: Routes.queueItem,
      search: `currantQueue=${queue.queuename}`,
      state: { queueid: queue?.queueid, queue_id: queue?._id },
    });
    history.go();
    props.handleClose();
  };
  return (
    <React.Fragment>
      <div style={{ height: "100%", position: "relative" }}>
        <Box className={classes.Header}>
          <Typography sx={{ p: "8px 0" }}>Queue Item's</Typography>
        </Box>
        <div
          style={{
            height: "calc(100% - 52px)",
            padding: "12px 16px",
          }}
        >
          {props.queues?.map((item, index) => (
            <QueueCard
              onClick={() => NavigateRoute(item)}
              color={theme.palette.info.main}
              hovercolor={theme.palette.info.light}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <TaskIcon size="1.3rem" color={theme.palette.text.disabled} />

                <Typography
                  className="title"
                  variant="body1"
                  sx={{ mx: "8px" }}
                >
                  {item.queuename}
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ChevronRightRoundedIcon className="title" />
              </div>
            </QueueCard>
          ))}
        </div>
        <IconButton
          className={classes.closeIcon}
          onClick={() => props.handleClose()}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </React.Fragment>
  );
};
