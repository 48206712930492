import { Box, Tab, Typography } from "@mui/material";
import React from "react";
import StarIcon from "@mui/icons-material/Star";
import { StyledButton2, StyledLabel } from "../../../styledcomponent";
import { useTheme } from "@emotion/react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ListPanel } from "./listPanel";
import axios from "axios";
import { AlertProps } from "../../../utils";
import { AlertContext, BackdropContext } from "../../../contexts";
// import { Favourite } from "./favourite";
// import { styled } from "@mui/styles";

/* const MyTab = styled(Tab)(({ theme }) => ({
  transition: "all .4s ease-out",
  padding: "2px",
  height: "30px",
  //   "&.Mui-selected": {
  //     borderRadius: "4px",
  //   },
})); */

export const QueueScenarios = (props) => {
  //   console.log(props);
  const theme = useTheme();
  const [value, setValue] = React.useState("1");
  const alertMessage = React.useContext(AlertContext);
  const [scenarioState, setscenarioState] = React.useState([]);
  const [statecount, setCount] = React.useState([]);
  const [allcount, setAllCount] = React.useState("0");
  const db = atob(sessionStorage.getItem("ProjectDbname"));
  const backDrop = React.useContext(BackdropContext);
  const [favlist, setFavlist] = React.useState([]);

  const updateFavState = (val) => {
    setFavlist(val);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (props.queueId && props.currantScenario) {
      getScenarioStateData(props.queueId, props.currantScenario);
      getScenarioStateCount(props.queueId, props.currantScenario);
      getAllcount(props.queueId, props.currantScenario);
    }
    //eslint-disable-next-line
  }, [props.queueId, props.currantScenario]);

  const getScenarioStateData = async (queueid, scenarioid) => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "queuescenariostatemapping",
        filter: `queuescenariostatemapping.queueid=='${queueid}'&&queuescenariostatemapping.scenarioid=='${scenarioid}' && queuescenariostatemapping.activestatus==true`,
        return_fields: "{scenarioState:queuescenariostatemapping.states}",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      backDrop.setBackDrop({
        ...backDrop,
        open: true,
        message: "Fetching Scenarios Data",
      });

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result[0];
            setscenarioState(data?.scenarioState);
            backDrop.setBackDrop({
              ...backDrop,
              open: false,
            });
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const getScenarioStateCount = async (queueid, scenarioid) => {
    try {
      let params = {
        db_name: `${db}`,
        filter: {
          queueId: `${queueid}`,
          scenarioId: `${scenarioid}`,
        },
        queryid: "fa7f256d-0eb7-47df-8677-302ea0927ded",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data) {
            let data = res.data[0];
            setCount(data?.grps);
          } else if (res.error) {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: `QDM Queries ${res.error}`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const getAllcount = async (queueid, scenarioid) => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "QDMATPtransactionlog",
        filter: `QDMATPtransactionlog.queueIds=='${queueid}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioid}'`,
        return_fields: "QDMATPtransactionlog",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result;
            setAllCount(data?.length);
            // console.log(data);
            // setscenarioState(data?.scenarioState);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const getCount = (id) => {
    let co = statecount?.filter((l) => l.state === id);
    if (co && co[0]?.cnt) {
      return co[0]?.cnt;
    } else {
      return "0";
    }
  };

  return (
    <>
      <div style={{ height: "100%" }}>
        <TabContext value={value}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              // height: "64px",
              background: "transparent",
              // padding: "8px 8px",
            }}
          >
            <TabList
              onChange={handleChange}
              scrollButtons
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                style: {
                  height: "3px",
                  borderRadius: "5px 5px 0px 0px",
                  background: theme.palette.info.main,
                },
              }}
              color="info"
            >
              <Tab
                value={"0"}
                // label="All"
                icon={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // padding: "8px 8px",
                    }}
                  >
                    <StyledButton2>
                      <StarIcon fontSize="small" color="warning" />
                      <Typography
                        variant="subtitle2"
                        style={{ marginLeft: "8px" }}
                      >
                        {"favourite"}
                      </Typography>
                      <StyledLabel color={theme.palette.action.hover}>
                        {`${favlist.length}`}
                      </StyledLabel>
                    </StyledButton2>
                  </div>
                }
                // iconPosition="end"
                sx={{ minHeight: "54px" }}
              />

              <Tab
                value={"1"}
                label={`All (${allcount})`}
                sx={{ minHeight: "54px" }}
              />
              {scenarioState?.map((l, index) => (
                <Tab
                  value={`${index + 2}`}
                  label={`${l.states} (${getCount(l._id)})`}
                  key={index}
                  sx={{ minHeight: "54px" }}
                />
              ))}
            </TabList>
          </Box>
          <TabPanel
            style={{
              height: "calc(100% - 64px)",
              overflowY: "auto",
              padding: "0",
            }}
            value={"0"}
          >
            <ListPanel
              favtab={true}
              each={false}
              queueId={props.queueId}
              scenarioId={props.currantScenario}
              scenarioState={scenarioState}
              getScenarioStateData={getScenarioStateData}
              getScenarioStateCount={getScenarioStateCount}
              updateFavState={updateFavState}
              favlist={favlist}
              setValue={setValue}
              updateScenaorio={props.updateScenaorio}
            />
          </TabPanel>
          <TabPanel
            style={{
              height: "calc(100% - 64px)",
              overflowY: "auto",
              padding: "0",
            }}
            value={"1"}
          >
            <ListPanel
              favtab={false}
              each={false}
              queueId={props.queueId}
              scenarioId={props.currantScenario}
              scenarioState={scenarioState}
              getScenarioStateData={getScenarioStateData}
              getScenarioStateCount={getScenarioStateCount}
              updateFavState={updateFavState}
              favlist={favlist}
              setValue={setValue}
              updateScenaorio={props.updateScenaorio}
            />
          </TabPanel>
          {scenarioState?.map((l, index) => (
            <TabPanel
              value={`${index + 2}`}
              key={index}
              style={{
                height: "calc(100% - 64px)",
                overflowY: "auto",
                padding: "0",
              }}
            >
              <ListPanel
                favtab={false}
                each={true}
                queueId={props.queueId}
                scenarioId={props.currantScenario}
                stateid={l._id}
                scenarioState={scenarioState}
                setValue={setValue}
                updateFavState={updateFavState}
                favlist={favlist}
                getScenarioStateData={getScenarioStateData}
                getScenarioStateCount={getScenarioStateCount}
                updateScenaorio={props.updateScenaorio}
              />
            </TabPanel>
          ))}
        </TabContext>
      </div>
    </>
  );
};
