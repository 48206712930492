import React from "react";
import { SvgIcon } from "@mui/material";
export const TaskIcon = (props) => {
  const { color } = props;
  return (
    <SvgIcon
      width="20px"
      height="20px"
      style={{ margin: "4px" }}
      viewBox="0 0 20 20"
    >
      <g
        // xmlns="http://www.w3.org/2000/svg"
        stroke={color}
        // fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.4px"
      >
        <path
          d="M3.18 6.6a8.23 8.23 0 1112.93 9.94h0a8.23 8.23 0 01-11.63 0"
          stroke={color}
          fill="none"
          stroke-width="1.4px"
        />
        <path
          d="M6.44 7.25H2.55V3.36M10.45 6v5.6M10.45 11.6L13 13"
          stroke={color}
          fill="none"
          stroke-width="1.4px"
        />
      </g>
    </SvgIcon>
  );
};
