import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import { MeetingRoom } from '@mui/icons-material';
import { LocalStorageKeys } from "../../../utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import MenuIcon from "@mui/icons-material/Menu";

import {
  Avatar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItem,
  ListItemText,
  Grid,
  // NativeSelect,
  Divider,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
// import { SideNavBar } from '..';
import Popover from "@mui/material/Popover";
// import Button from '@mui/material/Button';
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  title: {
    display: "block",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userProfilecard: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
  },
  profilePic: {
    marginLeft: "8px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,

    "& li": {
      // fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "1rem",
      fontFamily: "TDS_Regular",
    },
    "& li:hover": {
      color: theme.palette.common.white,
      background: theme.palette.info.main,
    },
    "& li.Mui-selected": {
      color: theme.palette.common.black,
      background: theme.palette.info.light,
    },
    "& li.Mui-selected:hover": {
      // background: "#6EC177",
      color: theme.palette.common.white,
      background: theme.palette.info.main,
    },
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #e0e0e0",
    fontSize: "1rem",
    fontFamily: "TDS_Regular",
    padding: "10px 26px 10px 12px",
    // minWidth: "60px",
    // transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    // "&:focus": {
    //   borderRadius: 4,
    //   // borderColor: "#80bdff",
    //   // boxShadow: "0 0 0 0.5rem rgba(0,123,255,.25)",
    // },
    // "& .MuiNativeSelect-select": {
    //   padding: "24px",
    // },
    // "& :not([multiple]) option": {
    //   height: "40px",
    // },
  },
}));

export const TopNavBar = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [scenarios, setAge] = React.useState("");

  React.useEffect(() => {
    setAge(props?.defaultScenarios);
  }, [props?.defaultScenarios]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem(LocalStorageKeys.authToken);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const handleChange = (value) => {
  //   setAge(value);
  //   props.updateScenaorio(value);
  //   // console.log(value);
  // };

  const handleChangew = (event) => {
    setAge(event.target.value);
    props.updateScenaorio(event.target.value);
  };

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar sx={{ padding: "0 16px" }}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title} variant="body1" noWrap>
              {"Task Viewer"}
            </Typography>
            {props.QueueName && (
              <>
                <Divider
                  flexItem
                  orientation="vertical"
                  sx={{ mx: 1, my: 1.5 }}
                />
                {/* <hr style={{ height: 25, margin: "0px 8px" }} /> */}
                <Typography variant="subtitle1">{props.QueueName}</Typography>
              </>
            )}
            {props.QueueName && props.Scenarios.length !== 0 && (
              <>
                <Divider
                  flexItem
                  orientation="vertical"
                  sx={{ mx: 1, my: 1.5 }}
                />
                <Grid item lg={3}>
                  {/* <NativeSelect
                    defaultValue={props?.defaultScenarios}
                    size="small"
                    fullWidth
                    value={props?.defaultScenarios}
                    onChange={(e) => handleChange(e.target.value)}
                    inputProps={{
                      name: "senarios",
                      id: "uncontrolled-native",
                    }}
                    input={<BootstrapInput />}
                  >
                    {props.Scenarios?.map((Scenario) => (
                      <option className="option" value={Scenario?._id}>
                        {Scenario?.scenarioname}
                      </option>
                    ))}
                  </NativeSelect> */}
                  <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={scenarios}
                      onChange={handleChangew}
                      input={<BootstrapInput />}
                      MenuProps={menuProps}
                    >
                      {props.Scenarios?.map((v, index) => {
                        return (
                          <MenuItem key={index} value={v._id}>
                            {v.scenarioname}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
          </div>

          <div className={classes.grow} />

          <div className={classes.userProfilecard}>
            <Typography className={classes.title} variant="body1" noWrap>
              {/* userName */}
              Admin
            </Typography>
            <Avatar className={classes.profilePic} variant="rounded">
              A
            </Avatar>
          </div>
          <IconButton onClick={handleClick}>
            <ExpandMoreIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleLogout()}>
              <ListItemIcon sx={{ width: "30px" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};
