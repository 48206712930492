import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import Typography from "@mui/material/Typography";
import "rc-steps/assets/index.css";

import { styled } from "@mui/material/styles";
// import "rc-steps/assets/iconfont.css";
// import { makeStyles } from "@material-ui/core";

/* const useStyles = makeStyles((theme) => ({
  cdsr: {
    "&::before": {
      flex: 0,
    },
  },
  lineDot: {
    margin: "unset",
  },
  connecter: {
    width: "1px",
    margin: "3px 0",
  },
  connecter1: {
    width: "1px",
  },
  lineseparator: {
    margin: "0px",
  },
  typo: {
    padding: "0px 16px",
    marginTop: "-5px",
  },
})); */

const StyledTimeLineItem = styled(TimelineItem)(({ theme }) => ({
  "&::before": {
    flex: 0,
  },
}));

// import Steps, { Step } from "rc-steps";

export const TimeLine = (props) => {
  const getTime = (epoche) => {
    let a = new Date(epoche * 1000);
    /* const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }; */
    // let value = a.toLocaleDateString();
    return a;
  };
  return (
    <>
      <Timeline>
        {props.scenarioState?.map((state, index) => (
          <StyledTimeLineItem key={index}>
            <TimelineSeparator>
              {state._id === props.currantState.statesid ? (
                <TimelineDot color="info">
                  <ArticleOutlinedIcon variant="filled" />
                </TimelineDot>
              ) : (
                <TimelineDot variant="outlined">
                  <AccessTimeIcon color="disabled" />
                </TimelineDot>
              )}
              {!(state._id === props.scenarioState.at(-1)._id) && (
                <TimelineConnector />
              )}
            </TimelineSeparator>
            {!state.end ? (
              <TimelineContent style={{ marginTop: "10px" }}>
                <Typography
                  variant="body1"
                  component="span"
                  style={{ display: "block" }}
                >
                  {`Request Sent To "${state.states}"`}
                </Typography>
                {state?.statustime ? (
                  <Typography variant="caption">
                    {`${state?.userAction} action  held on ${getTime(
                      state?.statustime
                    )}`}
                  </Typography>
                ) : (
                  <Typography variant="caption">{`Action not yet performed!`}</Typography>
                )}
              </TimelineContent>
            ) : (
              <TimelineContent style={{ marginTop: "10px" }}>
                <Typography
                  variant="body1"
                  component="span"
                  style={{ display: "block" }}
                >
                  {`Request ${state.states}`}
                </Typography>
              </TimelineContent>
            )}
          </StyledTimeLineItem>
        ))}
      </Timeline>
      {/*  <Steps direction="vertical" status="wait">
        {props.scenarioState?.map((state) =>
          !state.end ? (
            <Step
              title={`Request Sent To "${state.states}"`}
              description={
                state?.statustime
                  ? `Action ${state?.userAction}  held on ${getTime(
                      state?.statustime
                    )}`
                  : "No Record Found"
              }
              icon={
                state._id === props.currantState.statesid ? (
                  <ArticleOutlinedIcon style={{ margin: "4px 0px" }} />
                ) : (
                  <AccessTimeIcon style={{ margin: "4px 0px" }} />
                )
              }
            ></Step>
          ) : (
            <Step
              title={`Requested ${state.states}`}
              icon={
                state._id === props.currantState.statesid ? (
                  <ArticleOutlinedIcon style={{ margin: "4px 0px" }} />
                ) : (
                  <AccessTimeIcon style={{ margin: "4px 0px" }} />
                )
              }
            ></Step>
          )
        )}
      </Steps> */}
    </>
  );
};
